import React, { 
  useEffect, 
  useState, 
  useCallback  
} from 'react'
import { 
  Breadcrumbs, 
  EmptyState 
} from 'components/AffiliateDashboard'
import {
  Wrapper,
  Grid
} from './styles'
import { ClientCard } from './components'
import { AffiliateClientsURL } from 'commons/API'
import { useAuth } from '../../../providers/auth-provider'
import { useToasts } from 'react-toast-notifications'
import { Shimmer } from 'components'
import AffiliateAPI from 'commons/API/affiliate'

const AFFILIATE_STATUS = {
  ACTIVE  : 'Active',
  PENDING : 'Request to Join'
}

const ClientsView = ({ location }) => {

  const { user } = useAuth()
  const { addToast } = useToasts()

  const [clientList, setClientList] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const fetchClients = useCallback(async () => {
    setIsLoading(true)
    await AffiliateAPI.post(AffiliateClientsURL.PostClientList)
      .then((response) => {
        const data = response.data.accounts
        setClientList(data || [])
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        if(error.response.status !== 403){
          addToast(error.response.data.message, { appearance: 'warning' })
        }
      })


  }, [addToast])

  useEffect(() => {
    fetchClients()
  }, [fetchClients])

  const ClientLisCard = () => {

    if(!clientList.length || user.affiliate_status?.name === AFFILIATE_STATUS.PENDING) return (
      <EmptyState text={ 'Sorry, You don\'t have a client yet.' }/>
    )
    
    return(
      <Grid>
        {
          clientList.map((client, i) => (
            <ClientCard 
              key={ client.company_name }
              client={ client } 
            />
          ))
        }
      </Grid>
    )
  }

  return(
    <Wrapper>
      <Breadcrumbs location={ location } />
      <h2>
        My Clients
      </h2>
      {
        isLoading
          ? (
            <>
              <Shimmer/>
              <Shimmer/>
              <Shimmer/>
              <Shimmer/>
              <Shimmer/>
              <Shimmer/>
              <Shimmer/>
            </>
          )
          : <ClientLisCard />
      }
    </Wrapper>
  )
}

export default ClientsView
