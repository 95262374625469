import React from 'react'
import { Link } from 'gatsby'
import {
  Card,
  Detail,
  RedirectWrapper
} from './styles'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { AffiliateDashButton } from 'components/AffiliateDashboard'

const propTypes = {
  client: PropTypes.shape({
    id              : PropTypes.string,
    company_name    : PropTypes.string,
    email           : PropTypes.string,
    created_at      : PropTypes.string,
    account_package : PropTypes.shape({
      id   : PropTypes.string,
      name : PropTypes.string
    })
  })
}

const defaultProps = {
  client: PropTypes.shape({
    id              : '',
    company_name    : '',
    email           : '',
    created_at      : '',
    account_package : PropTypes.shape({
      id   : '',
      name : ''
    })
  })
}

const ClientCard = ({ client }) => (
  <Card>
    <h5>
      { client.company_name }
    </h5>
    
    <Detail>
      <div>
        Email
      </div>
      <div>
        :
      </div>
      <div>
        { client.email }
      </div>
    </Detail>

    <Detail>
      <div>
        Package
      </div>
      <div>
        :
      </div>
      <div>
        { client.account_package.name }
      </div>
    </Detail>

    <Detail>
      <div>
        Join date
      </div>
      <div>
        :
      </div>
      <div>
        { dayjs(client.created_at).format('D MM YYYY') }
      </div>
    </Detail>
    <RedirectWrapper>
      <Link to={ `/affiliate-dashboard/clients/manage-billing/?client_id=${client.id}` }>
        <AffiliateDashButton type='warning'>
          Manage Billing
        </AffiliateDashButton>
      </Link>
      { /* pending implementation */ }
      { /* 
      <Link to='#'>
        Details
        <ArrowRight />
      </Link> */ }
    </RedirectWrapper>
  </Card>
)

ClientCard.propTypes = propTypes
ClientCard.defaultProps = defaultProps

export default ClientCard
