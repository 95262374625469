import { lightDarkBlue } from 'components/baseColor'
import styled from 'styled-components'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

export const Wrapper = styled.div`
  margin: 1rem 8rem;
  font-family: "Open Sans", system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial,
  sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  color: ${lightDarkBlue};
  min-height: 75vh;

  h2 {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.25px;
    font-size: 1.4rem;
  }

  ${IS_FRAME_TABLET} {
    margin: 1rem 6rem;
    min-height: 75vh;
  }

  ${IS_FRAME_MOBILE} {
    margin: 1rem 1rem 2rem;
    min-height: 75vh;

    h2 {
      font-size: 1.3rem;
    }
  }
`

export const Grid = styled.div`
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: 1fr 1fr 1fr;
  
  ${IS_FRAME_TABLET} {
    display: block;
  }

  ${IS_FRAME_MOBILE} {
    display: block;
  }
`
