import React from 'react'
import { AffiliateDashboardLayout } from 'components/AffiliateDashboard'
import ClientsView from 'domains/AffiliateDashboard/Clients'
import withAuth from '../../hocs/with-auth'

const redirectUrl = '/affiliate-dashboard/login?redirectUrl=/affiliate-dashboard/clients'

const Clients = ({ location }) => (
  <AffiliateDashboardLayout>
    <ClientsView location={ location } />
  </AffiliateDashboardLayout>
)

export default withAuth(Clients, redirectUrl)
