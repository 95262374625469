import { lightDarkBlue, mykonosBlue } from 'components/baseColor'
import styled from 'styled-components'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

export const Card = styled.div`
  border: 1px solid #dee2ee;
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;
  color: ${lightDarkBlue};
  font-family: "Open Sans", system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial,
  sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  color: ${lightDarkBlue};
  word-break: break-all;

  > h5 {
    font-weight: 500;
    font-size: 1.2rem;
    margin: 0 0 0.75rem;
  }

  ${IS_FRAME_TABLET} {
    margin: 1.25rem 0;
  }

  ${IS_FRAME_MOBILE} {

    padding: 1rem;
    margin: 1.25rem 0;

    > h5 {
      font-size: 1.1rem;
      font-weight: bold;
    }
  }
`

export const RedirectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;  

   a {
    color: ${mykonosBlue};
    display: flex;
    align-items: center;
    letter-spacing: 0.25px;
    font-weight: 600;
    font-size: 0.875rem;
    justify-content: flex-end;
    text-decoration: none;

    > svg path {
      fill: ${mykonosBlue};
    }
  }
`

export const Detail = styled.div`
  display: flex;
  margin: 0.5rem 0;
  letter-spacing: 0.25px;
  font-size: 0.9rem;

  div {
    &:first-child {
      flex: 0 0 30%;
    }

    &:last-child {
      padding-left: 1rem;
      line-break: anywhere;
    }
  }
`
